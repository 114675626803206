<template>
  <div class="container">
    <!-- Blog Posts -->
    <div class="blog-page">
      <div class="row mt-12">
        <!-- Post Content -->
        <div class="col-lg-9 col-md-8 padding-right-30">
          <!-- Blog Post -->
          <div class="blog-post single-post pt-32 px-24 py-10">
            <!-- Img -->
            <img class="post-img" src="images/valo2.jpg" alt="" />

            <!-- Content -->
            <p class="MsoNormal" style="text-align: justify">
              <span
                style="
                  font-size: 14pt;
                  mso-bidi-font-size: 11pt;
                  line-height: 107%;
                  font-family: 'Yu Gothic UI Semibold', sans-serif;
                "
              >
                Valorisez votre terre agricole au meilleur prix grâce à notre
                mécanisme d'enchères.<o:p></o:p
              ></span>
            </p>
            <p
              class="MsoNormal"
              style="
                font-size: 14pt;
                mso-bidi-font-size: 11pt;
                line-height: 107%;
                font-family: 'Yu Gothic UI Semibold', sans-serif;
              "
            >
              Avec 60% des terres arables non exploitées dans le monde,
              l’Afrique est clairement une attraction pour toute sorte d'appétit
              d’achats et de valorisation de la terre agricole, premier facteur
              inextensible de la production agricole. Seulement, lorsque plus de
              100 milliards de dollars américains de terres sont échangées sur
              le continent depuis 2000, la transformation fondamentale du
              secteur pour les quelque 80% d’agriculteurs employés dans le
              secteur leur file entre les doigts. Pour une raison simple, tout
              ce que l’on ne mesure pas ne se valorise pas et ce qui ne se
              valorise pas est réattribué ou arraché activement ou non.<o:p
              ></o:p>
            </p>
            <p
              class="MsoNormal"
              style="
                font-size: 14pt;
                mso-bidi-font-size: 11pt;
                line-height: 107%;
                font-family: 'Yu Gothic UI Semibold', sans-serif;
              "
            >
              La valeur de la terre agricole en Afrique est, entre autres,
              inadéquatement déterminée parce que le marché est fragmenté, les
              lois et réglementations sont instables et peu transparentes et
              tout naturellement un gros échec de marché perdure. Tout ceci
              empêche de convertir chaque opportunité d’une terre agricole en un
              capital, soit par la naissance et le transfert du droit de
              propriété ou l’organisation avec le maximum d'efficacité d’un
              marché de transactions documentées et traçables. Pour attribuer la
              valeur à un bien de la façon la plus efficiente possible, et
              notamment&nbsp; en cas de “doute”, l'enchère est un presqu’un
              recours obligé. Elle favorise la transparence, permet aux acteurs
              engagés de faire de bonnes affaires; d'où résulte
              l'efficience.<o:p></o:p>
            </p>
            <p></p>
            <p
              class="MsoNormal"
              style="
                font-size: 14pt;
                mso-bidi-font-size: 11pt;
                line-height: 107%;
                font-family: 'Yu Gothic UI Semibold', sans-serif;
              "
            >
              En temps réel et de façon continue assurer le fil des transactions
              des terres agricoles au bout d’une application mobile en deux
              cliques, telle est la solution LandsAuction de LandsApp.
              Plateforme alimentée par un algorithme qui organise et tourne
              l'enchère des terres agricoles pour déterminer le plus disant,
              l’application entend révolutionner comment les terres agricoles en
              Afrique sont échangées en injectant de la transparence dans le
              marché, et faisant un projecteur sur les transactions de toute
              taille, entre institutionnels, privés et individuels, de facto
              distribuant la terre agricole vers les entités qui la valorisent
              le mieux pour débloquer la transformation agricole et la fin de la
              faim sur le continent.<o:p></o:p>
            </p>
          </div>
          <!-- Blog Post / End -->

          <!-- Post Navigation -->

          <!-- About Author -->
          <div class="about-author">
            <img src="images/boris.JPG" alt="" />
            <div class="about-description">
              <h4>Boris Houenou, Ph.D.</h4>
              <a href="#">boris.houenou@landsapp.com</a>
              <p>
                is a US-trained economist with a Ph.D. who has spearheaded
                several industry transformations in Africa, including
                agribusiness and tech. He is active in the space of mobilizing
                artificial intelligence, technology, and economics to solve key
                problems standing between Africa and prosperity.
              </p>
            </div>
          </div>

          <!-- Related Posts -->

          <!-- Related Posts / End -->

          <div class="margin-top-50"></div>

          <!-- Reviews -->

          <div class="clearfix"></div>

          <!-- Add Comment -->

          <!-- Add Review Box / End -->
        </div>
        <!-- Content / End -->

        <!-- Widgets -->
        <div class="col-lg-3 col-md-4">
          <div class="sidebar right">
            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->

            <!-- Widget / End -->

            <!-- Widget -->
            <div class="widget" style="margin-top: 80px">
              <h3>Popular Posts</h3>
              <ul class="widget-tabs">
                <!-- Post #1 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails1' })"
                        ><img src="images/valorisation.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails1' })"
                          >Valoriser les terres agricoles pour mettre fin à la
                          faim
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #2 -->
                <li>
                  <div class="widget-content cursor-pointer">
                    <div class="widget-thumb">
                      <a @click="$router.push({ name: 'BlogDetails2' })"
                        ><img src="images/valo2.jpg" alt=""
                      /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a @click="$router.push({ name: 'BlogDetails2' })"
                          >Valorisez votre terre agricole au meilleur prix grâce
                          à notre mécanisme d'enchères.</a
                        >
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>

                <!-- Post #3 -->
                <li>
                  <div class="widget-content">
                    <div class="widget-thumb">
                      <a><img src="images/valo3.jpg" alt="" /></a>
                    </div>

                    <div class="widget-text">
                      <h5>
                        <a
                          >Déterminez le profil cultural de votre terre grâce à
                          l'intelligence artificielle de LandsApp.
                        </a>
                      </h5>
                      <span>Febuary 27, 2022</span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Widget / End-->

            <div class="clearfix"></div>
            <div class="margin-bottom-40"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sidebar / End -->
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
